import React from "react";
import { useFormContext } from "react-hook-form";

export default function NewsletterCheckbox() {
    const {register} = useFormContext();

    return (
            <div className="flex items-center my-4">
                <input name="subscribe" type="checkbox" {...register('subscribe')} id="subscribe" className={`w-4 h-4 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600`} />
                <label htmlFor="subscribe" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                    Ja, jag vill ta del av förmånliga erbjudanden och nyheter från Nässjö järnvägsmuseum
                </label>
            </div>
    );
}
