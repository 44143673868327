import React from 'react';
import ReactDOM from 'react-dom';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";

import { Elements } from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";

import Header from "./Header";
import Footer from "./Footer";
import Stations from "./Stations";
import Book from "./Book";
import TrainList from "./TrainList";
import Travelers from "./Travelers";
import Train from "./Train";
import Steps from "./Steps";
import Confirmation from "./Confirmation";
import MyPages from './MyPages';
import AuthLayout from './layouts/AuthLayout';
import GuestLayout from './layouts/GuestLayout';
import VerifyEmail from './auth/VerifyEmail';

const promise = loadStripe(process.env.MIX_STRIPE_PUBLIC_KEY);

export default function App() {
    return (
        <>
            <div className="container mx-auto px-4 sm:px-6 lg:px-8">
                <Router>
                        <Header />
                        <Steps />
                        <Switch>
                            <Route exact path={["/", "/trains"]} component={TrainList} />
                            <Route path="/trains/:id/seats" component={Train} />
                            <Route path="/trains/:id/book">
                                <Elements stripe={promise}>
                                    <Book />
                                </Elements>
                            </Route>
                            <Route path="/trains/:id/travelers" component={Travelers} />
                            <Route path="/trains/:id/station" component={Stations} />
                            <Route path="/confirmation" component={Confirmation} />
                            <Route path={["/my-pages/bookings", "/email/verification-notice"]}>
                                <AuthLayout>
                                    <MyPages/>
                                    <VerifyEmail/>
                                </AuthLayout>
                            </Route>
                            <Route path={["/login", "/register", "/my-pages/forgot-password", "/my-pages/reset-password/:token"]}>
                                <GuestLayout/>
                            </Route>
                        </Switch>
                </Router>
            </div>
            <Footer />
        </>
    )
}
