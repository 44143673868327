import React, { useEffect, useState } from 'react'
import {useParams, useHistory} from "react-router-dom";
import ForgotPasswordService from '../../services/ForgotPasswordService';

const ResetPassword = () => {
    const [email, setEmail] = useState('');
    const [password01, setPassword01] = useState('');
    const [password02, setPassword02] = useState('');
    const [status, setStatus] = useState('');
    const [errors, setErrors] = useState([]);
    const [errorMsg, setErrorMsg] = useState('');
    const { token } = useParams();
    const [signature, setSignature] = useState('');
    const [expiration, setExpiration] = useState('');
    const queryParams = new URLSearchParams(window.location.search);
    const history = useHistory();

    useEffect(() => {
        setEmail(queryParams.get("email"));
        setExpiration(queryParams.get("expires"));
        setSignature(queryParams.get("s"));
    },[]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setStatus('');
        const res = await ForgotPasswordService.resetPassword({
            email,
            password: password01,
            password_confirmation: password02,
            token,
            expiration,
            signature
        });
        setPassword01('');
        setPassword02('');
        if(res && res.isOK) {
            setStatus('Ditt lösenord har återställts');
            setErrors([]);
            setErrorMsg('');
            history.push('/login');
        } else if(res && res.errors) { 
            setErrors(res.errors);
        } else if(res && res.error) {
            console.log(res.message);
            setErrors([]);
        } else if(res && res.errorMsg) {
            setErrorMsg(res.errorMsg);
            setErrors([]);
        } else {
            console.log('Unhandled backend error');
        }
    };
                
  return (
    <div className='w-full px-4'>
        <div className='px-6 py-6 border border-indigo-700 rounded-md bg-gray-100 max-w-xl mx-auto align-content-center'>
            {status && (<div className='w-full bg-green-700 rounded-md text-white my-2 py-2 text-center'>
                {status}
            </div>)}
            {errorMsg && (<div className='w-full bg-red-700 rounded-md text-white my-2 py-2 text-center'>
                {errorMsg}
            </div>)}
            <form onSubmit={handleSubmit}>
                <h2 className='text-lg font-semibold pb-2'>Vänligen fyll i nytt lösenordet</h2>
                <div className='pb-4'>
                    <div className='pb-1 text-sm'>
                        <label className="" htmlFor="password">Nytt lösenord</label>
                    </div>
                    <div>
                        <input className='w-full rounded-md' type="password" id="password01" name="password01" autoComplete="off" required="" minLength="8"
                        value={password01}
                        onChange={e => setPassword01(e.target.value)} />
                    </div>
                    {errors.password && (<span className='text-red-400 text-sm p-2 m-2'>{errors.password[0]}</span>)}
                </div>
                <div className='pb-4'>
                    <div className='pb-1 text-sm'>
                        <label className="" htmlFor="password">Upprepa lösenordet</label>
                    </div>
                    <div>
                        <input className='w-full rounded-md' type="password" id="password02" name="password02" autoComplete="off" required="" minLength="8"
                        value={password02}
                        onChange={e => setPassword02(e.target.value)} />
                    </div>
                </div>
                <button className="px-2 py-2 w-full border border-transparent text-xl font-semibold rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" type="submit">
                    Spara
                </button>
            </form>
        </div>
    </div>
  );
};

export default ResetPassword;