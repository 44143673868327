import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import store from './store';

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import App from './components/App';
import { AuthProvider } from './components/context/AuthContext';

Sentry.init({
    dsn: "https://0d1d1af0c73840f586472bb98a012840@o1012686.ingest.sentry.io/5978276",
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});

ReactDOM.render(
    <Provider store={store}>
        <AuthProvider>
            <App />
        </AuthProvider>
    </Provider>,
    document.getElementById('app')
);
