import React, { useState } from 'react'
import VerifyEmailService from '../../services/VerifyEmailService';
import Spinner from '../Spinner';
import useAuthContext from '../context/AuthContext';

const VerifyEmail = () => {
	const [status, setStatus] = useState('');
	const [errorMsg, setErrorMsg] = useState('');
	const [processing, setProcessing] = useState(false);
	const {user, getUser} = useAuthContext();

	const handleSendLink = async (event) => {
        event.preventDefault();
		if(!user) {
			getUser();
		}
		setStatus('');
		setErrorMsg('');
		setProcessing(true);
		const res = await VerifyEmailService.sendVerificationEmail({email: user.email});
		if(res && res.message) {
			setStatus(res.message);
		} else if(res && res.error) {
			setErrorMsg(res.error);
		} else {
			console.log('An error occured...');
		}
		setProcessing(false);
	};

	return (
		<div className='px-6 py-6 border border-indigo-700 rounded-md bg-gray-100 max-w-3xl mx-auto align-content-center'>
			{status && (<div className='w-full bg-green-700 rounded-md text-white my-2 py-2 text-center'>
                {status}
            </div>)}
            {errorMsg && (<div className='w-full bg-red-700 rounded-md text-white my-2 py-2 text-center'>
                {errorMsg}
            </div>)}
			<p className='text-lg font-semibold pb-2'>Var vänlig och verifiera din e-postadress genom att klicka på länken i e-postmeddelandet vi precis skickade till dig. Tack!</p>
			<div className=' flex justify-between items-center'>
				<span>Tryck på knappen nedan för att skicka länken på nytt.</span>
				<form method="post" onSubmit={handleSendLink}>
					<button disabled={processing} className="px-2 py-2 w-full border border-transparent text-xl font-semibold rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" type="submit">
						{processing ? (<Spinner/>) : ("Skicka länken igen")}
					</button>
				</form>
			</div>
		</div>
	)
}

export default VerifyEmail;