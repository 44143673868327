import http from "../http-common";

const registerNewUser = data => {
    return http.post("/register", data)
    .then(response => {
        return {isOK: true};
    })
    .catch(error => {
        let e = error;
        if(e.response.status == 422) {
            console.log(e.response.data.message);
            return {errors: e.response.data.errors};
        } else {
            console.log(e.response.data.message);
            return {error: true, message: 'Ett fel inträffade. Kontakta oss eller försök igen...'};
        }
    });
};

const RegisterService = {
    registerNewUser
};

export default RegisterService;
