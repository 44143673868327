import http from "../http-common.js";

const logoutUser = () => {
    return http.post("/logout")
    .then(response => {
        return {isLoggedOut: true};
    })
    .catch(error => {
        console.log(error.response.data.message);
    });
};

const LogoutService = {
    logoutUser
};

export default LogoutService;
