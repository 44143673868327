import React from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom'
import RegisterService from '../../services/RegisterService';

const Register = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password01, setPassword01] = useState('');
    const [password02, setPassword02] = useState('');
    const [errors, setErrors] = useState([]);
    const history = useHistory();

    const handleRegister = async (event) => {
        event.preventDefault();
        const res = await RegisterService.registerNewUser({name, email, password: password01, password_confirmation: password02});
        setName('');
        setEmail('');
        setPassword01('');
        setPassword02('');
        if(res && res.isOK) {
            history.push('/login');
        } else if(res && res.errors) {
            setErrors(res.errors);
        } else if (res && res.error) {
            console.log(res.message);
        } else {
            console.log('Unhandled backend error');
        }
    }

    return (
    <div className='w-full px-4'>
        <div className='px-6 py-6 border border-indigo-700 rounded-md bg-gray-100 max-w-lg mx-auto align-content-center'>
            <form method="post" onSubmit={handleRegister}>
                <h2 className='text-xl font-semibold pb-4'>Registrera dig</h2>
                <div className='pb-4'>
                    <div className='pb-1 text-sm'>
                        <label htmlFor="name">Namn</label>
                    </div>
                    <div>
                        <input className='w-full rounded-md' type="text" id="name" name="name" autoComplete="off" required=""
                        value={name}
                        onChange={e => setName(e.target.value)} />
                    </div>
                    {errors.name && (<span className='text-red-400 text-sm p-2 m-2'>{errors.name[0]}</span>)}
                </div>
                <div className='pb-4'>
                    <div className='pb-1 text-sm'>
                        <label htmlFor="email">Email</label>
                    </div>
                    <div>
                        <input className='w-full rounded-md' type="email" id="email" name="email" autoComplete="off" required=""
                        value={email}
                        onChange={e => setEmail(e.target.value)} />
                    </div>
                    {errors.email && (<span className='text-red-400 text-sm p-2 m-2'>{errors.email[0]}</span>)}
                </div>
                <div className='pb-4'>
                    <div className='pb-1 text-sm'>
                        <label className="" htmlFor="password">Lösenord</label>
                    </div>
                    <div>
                        <input className='w-full rounded-md' type="password" id="password01" name="password01" autoComplete="off" required="" minLength="8"
                        value={password01}
                        onChange={e => setPassword01(e.target.value)} />
                    </div>
                    {errors.password && (<span className='text-red-400 text-sm p-2 m-2'>{errors.password[0]}</span>)}
                </div>
                <div className='pb-4'>
                    <div className='pb-1 text-sm'>
                        <label className="" htmlFor="password">Upprepa lösenordet</label>
                    </div>
                    <div>
                        <input className='w-full rounded-md' type="password" id="password02" name="password02" autoComplete="off" required="" minLength="8"
                        value={password02}
                        onChange={e => setPassword02(e.target.value)} />
                    </div>
                </div>
                <button className="px-2 py-2 w-full border border-transparent text-xl font-semibold rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" type="submit">
                    Spara
                </button>
            </form>
        </div>
    </div>
    );
};

export default Register;