import http from "../http-common";

const addSubscriber = data => {
    return http.post("/newsletters/add", data)
    .catch(error => {});
};

const NewsletterService = {
    addSubscriber,
};

export default NewsletterService;