import React from 'react';
import useAuthContext from './context/AuthContext';
import { useEffect, useState } from 'react';
import MyPagesService from '../services/MyPagesService';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';
import GetReservationForm from './GetReservationForm';
import ReservationViewer from './ReservationViewer';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const MyPages = () => {
  const {user, getUser} = useAuthContext();
  const [reservationsArr, setReservationsArr] = useState([]);
  const [errorMsg, setErrorMsg] = useState('');
  
  useEffect(async () => {
    if(!user) {
      getUser();
    }
    const res = await MyPagesService.getReservations({email: user.email, s: user.s});
    if(res && res.reservationsArr) {
      setReservationsArr(res.reservationsArr);
    } else if(res && res.error) {
      setErrorMsg(res.error.message);
    }
  }, []);

  return (
  <div>
      {user && (
        <div className='flex justify-center'>
            <h3 className="w-full md:max-w-5xl text-lg leading-6 font-medium text-gray-900">Hej {user.name},</h3>
        </div>
          )}
      {(reservationsArr && !errorMsg) && (
          <h3 className="mt-4 text-center text-base font-semibold text-indigo-600 tracking-wide uppercase">Din bokningar</h3>
          )}
      {errorMsg && (
          <h3 className="mt-4 text-center text-base font-semibold text-red-600 tracking-wide">{errorMsg}</h3>
      )}  

      {reservationsArr && reservationsArr.map((reservation, key) => (
        <div className='flex justify-center' key={reservation.base_booking_no}>
            <div className='w-full max-w-3xl border border-indigo-700 rounded-md mt-6'>
                <Disclosure as="div" key={reservation.base_booking_no} className="px-4 py-4 method-box">
                    {({open}) => (
                      <>
                            <dt className="text-lg">
                                <Disclosure.Button
                                    className="text-left w-full flex justify-between items-center text-gray-400">
                                    <span className="text-lg leading-6 font-medium text-gray-900">{reservation.base_booking_no}</span>
                                    <span className="ml-6 h-7 flex items-center">
                                      <ChevronDownIcon
                                          className={classNames(open ? '-rotate-180' : 'rotate-0', ' h-6 w-6 transform')}
                                          aria-hidden="true"
                                          />
                                    </span>
                                </Disclosure.Button>
                            </dt>
                            <Disclosure.Panel as="dd" className="mt-2 pr-12">
                                <ReservationViewer reservation={reservation} />
                            </Disclosure.Panel>
                        </>
                    )}
                </Disclosure>
            </div>
        </div>
      ))}
        <div className='flex justify-center py-4'>
            <div className='w-full max-w-3xl'>
                <GetReservationForm/>
            </div>
        </div>
  </div>
  );
};

export default MyPages;