import http from "../http-common";

const sendPasswordResetLink = data => {
    return http.post("/forgot-password", data)
    .then(response => {
        return {status: response.data.status};
    })
    .catch(error => {
        let e = error;
        if(e.response.status == 422) {
            console.log(e.response.data.message);
            return {errors: e.response.data.errors};
        } else if(e.response.status == 404) {
            console.log(e.response.data.message);
            return {error: true, message: e.response.data.message};
        } else {
            console.log(e.response.data.message);
            return {error: true, message: 'Ett fel inträffade. Kontakta oss eller försök igen...'};
        }
    });
};

const resetPassword = data => {
    return http.post("/reset-password", data)
    .then(response => {
        return {isOK: true};
    })
    .catch(error => {
        let e = error;
        if(e.response.status == 422) {
            console.log(e.response.data.message);
            return {errors: e.response.data.errors};
        } else if(Math.floor(e.response.status/100.0) == 4) {
            console.log(e.response.data.message);
            return (e.response.status == 400)
                ? {error: true, message: 'Ett fel inträffade.'}
                : {errorMsg: e.response.data.message};
        } else {
            console.log(e.response.data.message);
            return {error: true, message: 'Ett fel inträffade. Kontakta oss eller försök igen...'};
        }
    });
};

const ForgotPasswordService = {
    sendPasswordResetLink,
    resetPassword
};

export default ForgotPasswordService;