import React, { useState } from 'react'
import ForgotPasswordService from '../../services/ForgotPasswordService';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [status, setStatus] = useState('');
    const [errors, setErrors] = useState([]);
    const [errorMsg, setErrorMsg] = useState('');
    const handleSubmit = async (event) => {
        event.preventDefault();
        setEmail('');
        setStatus('');
        const res = await ForgotPasswordService.sendPasswordResetLink({email});
        if(res && res.status) {
            setStatus(res.status);
            setErrors([]);
            setErrorMsg('');
        } else if(res && res.errors) {
            setErrors(res.errors);
        } else if(res && res.error) {
            console.log(res.message);
            setErrorMsg(res.message);
            setErrors([]);
        } else {
            console.log("Error in ForgotPassword...");
            setErrors([]);
        }
    };
                
  return (
    <div className='w-full px-4'>
        <div className='px-6 py-6 border border-indigo-700 rounded-md bg-gray-100 max-w-xl mx-auto align-content-center'>
            {status && (<div className='w-full bg-green-700 rounded-md text-white my-2 py-2 text-center'>
                {status}
            </div>)}
            {errorMsg && (<div className='w-full bg-red-700 rounded-md text-white my-2 py-2 text-center'>
                {errorMsg}
            </div>)}
            <form onSubmit={handleSubmit}>
                <h2 className='text-lg font-semibold pb-2'>Vänligen fyll i e-postadressen som är kopplad till ditt konto</h2>
                <span className='text-sm'>
                    Vi skickar ut ett mail med information kring hur du återställer ditt lösenord
                </span>
                <div className='py-4'>
                    <input className='w-full rounded-md' type="email" id="email" name="email" placeholder="E-postadress"
                    value={email}
                    onChange={e => setEmail(e.target.value)} />
                    {errors.email && (<span className='text-red-400 text-sm p-2 m-2'>{errors.email[0]}</span>)}
                </div>
                <button className="px-2 py-2 w-full border border-transparent text-xl font-semibold rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" type="submit">
                    Fortsätt
                </button>
            </form>
        </div>
    </div>
  )
}

export default ForgotPassword