import React from 'react';
import { useState } from 'react';
import {useHistory} from "react-router-dom";
import LoginService from '../../services/LoginService';
import useAuthContext from '../context/AuthContext';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const {getUser} = useAuthContext();
    const history = useHistory();
    const handleLogin = async (event) => {
        event.preventDefault();
        const res = await LoginService.authenticateUser({email, password});
        setEmail('');
        setPassword('');
        if(res && res.isLoggedIn) {
            await getUser();
            history.push('/my-pages/bookings');
        } else if(res && res.message) {
            setErrorMsg(res.message);
        } else {
            setErrorMsg(res.errorMsg);
        }
    };
    return (
        <div className='w-full px-4'>
            <div className='px-6 py-6 border border-indigo-700 rounded-md bg-gray-100 max-w-lg mx-auto align-content-center'>
                <form onSubmit={handleLogin}>
                    <h2 className='text-xl font-semibold pb-4'>Logga in med din email</h2>
                    <div className='pb-4'>
                        <div className='pb-1 text-sm'>
                            <label htmlFor="email">Email</label>
                        </div>
                        <div>
                            <input className='w-full rounded-md' type="email" id="email" name="email" autoComplete="email" required=""
                            value={email}
                            onChange={e => setEmail(e.target.value)} />
                        </div>
                    </div>
                    <div className='pb-4'>
                        <div className='pb-1 text-sm flex justify-between'>
                            <label className="" htmlFor="password">Lösenord</label>
                            <span className='text-indigo-700 hover:underline'>
                                <a className="" href="my-pages/forgot-password" target="_blank">
                                    Glömt lösenord?
                                </a>
                            </span>
                        </div>
                        <div>
                            <input className='w-full rounded-md' type="password" id="password" name="password" autoComplete="password" required="" minLength="8"
                            value={password}
                            onChange={e => setPassword(e.target.value)} />
                        </div>
                        {errorMsg && (<span className='text-red-400 text-sm p-2 m-2'>{errorMsg}</span>)}
                    </div>
                    <button className="px-2 py-2 w-full border border-transparent text-xl font-semibold rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" type="submit">
                        Logga in
                    </button>
                </form>
            </div>
        </div>
    );
};

export default Login;