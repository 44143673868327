import React, { useState } from 'react'
import MyPagesService from '../services/MyPagesService';
import Spinner from './Spinner';
import ReservationViewer from './ReservationViewer';

const GetReservationForm = () => {
    const [email, setEmail] = useState('');
    const [bookingNo, setBookingNo] = useState('');
    const [reservation, setReservation] = useState(null);
    const [errors, setErrors] = useState([]);
    const [searchStatus, setSearchStatus] = useState('');
    const [processing, setProcessing] = useState(false);

    const handleSearchReservation = async (event) => {
        event.preventDefault();
        setProcessing(true);
        setErrors([]);
        setSearchStatus('');
        setReservation(null);
        const res = await MyPagesService.getReservationByData({email, reservation_number: bookingNo});
        setEmail('');
        setBookingNo('');
        setProcessing(false);
        if(res && res.reservation) {
            setReservation(res.reservation);
        } else if(res && res.errors) {
            setErrors(res.errors);
        } else if(res && res.error) {
            setSearchStatus(res.error.message);
        } 
    }

    return (
    <div>
        <form method='post' onSubmit={handleSearchReservation}>
            <h2 className='text-xl font-semibold pb-4'>Sök för bokningar</h2>
            <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-3">
                    <label className="block text-sm font-medium text-gray-700">
                        Email
                    </label>
                    <input
                        type="email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        autoComplete="on"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                    {errors.email && (<p className="mt-2 text-sm text-red-600">{errors.email[0]}</p>)}
                </div>
            
                <div className="col-span-6 sm:col-span-3">
                    <label className="block text-sm font-medium text-gray-700">
                        Bokningsnummer
                    </label>
                    <input
                        type="text"
                        value={bookingNo}
                        onChange={e => setBookingNo(e.target.value)}
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                    {errors.reservation_number && (<p className="mt-2 text-sm text-red-600">{errors.reservation_number[0]}</p>)}
                </div>
            </div>
            <button disabled={processing} className="flex justify-center px-2 py-2 mt-2 w-full border border-transparent text-xl font-semibold rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" type="submit">
                {processing ? (<Spinner/>) : ("Sök")}
            </button>
        </form>
        {reservation && (<ReservationViewer reservation={reservation} />)}
        {searchStatus && (
            <h3 className='pt-4 text-center text-base font-semibold text-red-600'>{searchStatus}</h3>
        )}
    </div>
  )
}

export default GetReservationForm