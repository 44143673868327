import http from "../http-common";

const getUser = () => {
    return http.get('/mypages/user')
    .then(response => {
        return response.data;
    })
    .catch(error => {
        let e = error;
        if (e.response.status == 404) {
            console.log(e.response.data.message);
        } else {
            console.log('Unknown backend error occured...');
            return {errorMsg: "Ett fel inträffade. Kontakta oss eller försök igen..."};
        }
    });
};

const getReservations = data => {
    return http.get('/mypages/reservation/' + data.email + '/' + data.s)
    .then(response => {
        return {'reservationsArr': response.data};
    })
    .catch(error => {
        let e = error;
        if(e.response.status == 404) {
            return {'error': e.response.data};
        } else if(e.response.status == 422) {
            console.log(e.response.data.message);
        } else {
            console.log('error', error);
        }
    });
};

const getReservationByData = (data) => {
    return http.post('/mypages/reservation', data)
    .then(response => {
        return {'reservation': response.data};
    })
    .catch(error => {
        let e = error;
        if(e.response.status == 404) {
            return {'error': e.response.data};
        } else if(e.response.status == 422) {
            return {'errors': e.response.data.errors};
        } else {
            console.log('error', error)
        }
    });
};

const MyPagesService = {
    getUser,
    getReservations,
    getReservationByData
};

export default MyPagesService;