import React from 'react';
import { Redirect } from "react-router-dom";
import useAuthContext from '../context/AuthContext';
import MyPages from '../MyPages';
import VerifyEmail from '../auth/VerifyEmail';

const AuthLayout = () => {
    const {user} = useAuthContext();

    return ((!user) ? 
        <Redirect to="/login"/> 
        : 
        ((user.verified) ?
            <MyPages />
            :
            <VerifyEmail />)
        );
};

export default AuthLayout;
