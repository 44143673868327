import React from 'react';
import { Redirect, Switch, Route } from "react-router-dom";
import useAuthContext from '../context/AuthContext';
import Login from '../auth/Login';
import Register from '../auth/Register';
import ForgotPassword from '../auth/ForgotPassword';
import ResetPassword from '../auth/ResetPassword';
import VerifyEmail from '../auth/VerifyEmail';

const GuestLayout = () => {
    const {user} = useAuthContext();
    
    return ((!user) ? 
        <Switch>
            <Route path="/login" component={Login} />
            <Route path="/register" component={Register} />
            <Route path="/my-pages/forgot-password" component={ForgotPassword} />
            <Route path="/my-pages/reset-password/:token" component={ResetPassword} />
        </Switch>
        :
        ((!user.verified) ?
            <Redirect to="/email/verification-notice"/>
            :
            <Redirect to="/my-pages/bookings"/>)
        );
};
export default GuestLayout;
