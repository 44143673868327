import http from "../http-common";

const sendVerificationEmail = data => {
        return http.post("/email/verification-notification", data)
    .then(response => {
        return {message: response.data};
    })
    .catch(error => {
        let e = error;
        if(e.response.status == 404) {
            return {error: e.response.data};
        } else {
            console.log('error', error);
        }
    });
};

const VerifyEmailService = {
    sendVerificationEmail
};

export default VerifyEmailService;
