import { useState, createContext, useContext } from "react";
import MyPagesService from "../../services/MyPagesService";
import LogoutService from "../../services/LogoutService";

const AuthContext = createContext();

export const AuthProvider = ({children}) => {
    const [user, setUser] = useState(null);
    
    const getUser = async () => {
        const data = await MyPagesService.getUser();
        if (data) {
            setUser(data);
        }
    };

    const logout = async () => {
        const res = await LogoutService.logoutUser();
        if(res && res.isLoggedOut) {
            setUser(null);
        }
    }

    return (<AuthContext.Provider value={{user, getUser, logout}}>
        {children}
    </AuthContext.Provider>);
}

export default function useAuthContext() {
    return useContext(AuthContext);
}