import http from "../http-common";

const authenticateUser = data => {
    return http.post("/login", data)
    .then(response => {
        return {isLoggedIn: true};
    })
    .catch(error => {
        let e = error;
        if (e.response.status == 400) {
            return {message: e.response.data.message}
        } else {
            console.log('Unknown backend error occured...');
            return {errorMsg: "Ett fel inträffade. Kontakta oss eller försök igen..."};
        }
    });
};

const LoginService = {
    authenticateUser
};

export default LoginService;
